/* Base Layout */
.research-page {
  display: flex;
  padding-top: 3rem;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--main-font) ;
  position: relative;
}

.survey-title {
  font-family: var(--main-font) !important;
}

.question-text {
  font-family: var(--main-font) !important;
}

/* Sidebar Styles */
.sidebar {
  width: 18rem;
  padding: 2rem;
  background-color: var(--darker-color);
  border-radius: 0 10px 10px 0;
  box-shadow: var(--shadow);
  position: fixed;
  left: 0;
  top: 3rem;
  bottom: 0;
  transition: transform 0.3s ease;
  overflow-y: auto;
  z-index: 8;
}

.sidebar h2 {
  font-size: 1.5rem;
  margin: 0 0 1rem;
  color: var(--background-color);
}

.sidebar.collapsed {
  transform: translateX(-100%);
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.toggle-sidebar {
  background: none;
  border: none;
  color: var(--darker-color);
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  position: fixed;
  left: 22rem;
  top: 7.5vh;
  z-index: 10;
}

.sidebar.collapsed + .toggle-sidebar {
  left: 0;
}

.toggle-sidebar:hover {
  background-color: var(--secondary-color);
}

.topic-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.topic-actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.premium-star {
  color: gold;
  font-size: 1.2rem;
  text-shadow: 0 0 2px rgba(0,0,0,0.3);
}

/* Adjust existing styles to accommodate the new layout */
.topic-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  background-color: var(--secondary-color);
  color: var(--background-color);
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.topic-list li:hover {
  background-color: var(--background-color);
  color: var(--secondary-color);
}

.topic-list li.premium-topic {
  background-color: var(--success-color);
  color: var(--secondary-color);
  }

.topic-list li.premium-topic:hover {
  background-color: var(--background-color);
  color: var(--secondary-color);
}

/* Adjust the trash icon for premium topics */
.topic-list li.premium-topic .click-icon {
  background-color: var(--apple-blue);
}

/* Button styles */
.button-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  margin-top: auto; /* This pushes the buttons to the bottom */
}

.research-button {
  min-width: 20%;
  max-width: 50%;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0.5rem;
  margin-top: 0.5rem;
  background-color: var(--tertiary-color);
  color: var(--background-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: var(--main-font); /* Ensure it uses the Poppins font */
  font-size: 1rem;
}

.research-button:hover {
  background-color: var(--background-color);
  color: var(--secondary-color);
}

.click-icon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  transition: opacity 0.2s;
  opacity: 0.7;
  background-color: var(--darker-color);
  padding: 0.3rem;
  border-radius: 0.5rem;
}

.click-icon:hover {
  opacity: 1;
}

/* Main Content Area */
.research-content {
  top: 1rem;
  background-color: var(--background-color);
  box-shadow: var(--shadow);
  width: 54rem;
  margin-left: 25%;
  padding: 3rem;
  transition: margin-left 0.3s ease;
  position: fixed;
  overflow-y: auto;
  height: 80vh;
}

.research-content.collapsed {
  margin: 0rem;
  margin-left: 0rem;
  align-self: center;
  width: 92%;
}


/* Tabs */
.tabs {
  display: flex;
  justify-content: center;
}

.tab {
  flex: 1;
  padding: 0.75rem;
  background-color: var(--secondary-color);
  color: var(--background-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 0.5rem;
  font-size: large;
  font-family: var(--main-font); /* Ensure it uses the Poppins font */
}

.tab:last-child {
  margin-right: 0;
}

.tab:hover, .tab.active {
  background-color: var(--darker-color);
}

.tab-content{
  text-align: center;
  justify-self: center;
  width: 100%;
}

/* Questions Styles */
.question-option {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-bottom: 0.5rem;
  width: 80%;
  border: 1px solid var(--secondary-color);
  border-radius: 0.5rem;
  font-family: var(--main-font);
  font-size: 1rem;
}

/* Research components */
.research-container {
  width: 95%;
  text-align: justify;
  display: flex;
  flex-direction: column;
  min-height: 80vh; /* This ensures the container takes up most of the viewport height */
}

.research-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  flex: 1; /* This allows the list to expand */
}


.research-list .container {
  width: 100%;
  max-width: none;
}

.research-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
  flex: 1; /* This allows the grid to expand */
}

.research-grid-item {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 1.5rem;
  transition: transform 0.2s;
  position: relative; /* Added for positioning the action buttons */
}

.grid-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.grid-details {
  margin: 1rem 0;
  text-align: left;
  width: 100%;
}

.grid-details p {
  margin: 0.5rem 0;
}

.grid-actions {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.2s;
}

.research-grid-item:hover .grid-actions {
  opacity: 1;
}

.list-profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.list-header {
  display: grid;
  grid-template-columns: 80px 1fr 80px 120px 100px 2fr 100px;
  background: var(--darker-color);
  color: var(--primary-color);
  padding: 1rem;
  font-weight: bold;
}

.research-list-item {
  border-bottom: 1px solid #eee;
}

.research-list-content {
  display: grid;
  grid-template-columns: 80px 1fr 80px 120px 100px 2fr 100px;
  padding: 1rem;
  align-items: center;
}

.list-actions {
  display: flex;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.2s;
}

.research-list-item:hover .list-actions {
  opacity: 1;
}


.profile-pic {
  width: 100px;
  height: 100px;
  margin: 10px auto;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--secondary-color);
}

.profile-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.research-edit-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.research-edit-form input,
.research-edit-form textarea {
  padding: 8px;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
}

.research-filters {
  background: var(--background-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: var(--shadow);
}

.filter-group {
  margin-bottom: 15px;
}

.filter-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.age-range {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filter-group select {
  padding: 8px;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  width: 100%;
}

.filter-group input {
  padding: 8px;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  width: 96.5%;
}

/* New Topic Form */
.new-topic {
  text-align: center;
  margin: 2rem;
  margin-top: 4rem;
}

.new-topic input,
.new-topic select {
  width: 90%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  font-family: var(--main-font);
  font-size: 1rem;
}

.research-textarea {
  width: 90%;
  min-height: 100px;
  padding: 12px;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  resize: vertical;  /* Allows user to resize vertically */
  font-family: var(--main-font);
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.research-textarea:focus {
  outline: none;
  border-color: var(--apple-blue);
  box-shadow: 0 0 0 2px rgba(0, 113, 227, 0.2);
}

@media (max-width: 1150px) {
  .research-content {
    margin-left: 0;
  }

  .sidebar {
    width: 80%;
  }

  .toggle-sidebar {
    left: calc(80% + 3.75rem);
    top: 4.5rem;

  }

  .sidebar.collapsed + .research-content {
    margin-left: 0;
  }

  .research-grid {
    grid-template-columns: 1fr;
  }
  .list-item {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
}
